import { Button, DatePicker, Input, Modal } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { useState } from 'react'
import { ErrorAlert, SuccessAlert } from '../Messages/Messages';

const ManualPayments = ({ id, refreshParentData }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        amount: '',
        date: '',
        description: ''
    });

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleChange = (key, value) => {
        setFormData({
            ...formData,
            [key]: value
        });
    };

    const submitHandler = () => {
        setLoading(true);
        axios.put(`${process.env.REACT_APP_BACKEND_URL}/contracts/add-payment/${id}`, { paymentData: formData }, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('token')
            }
        })
            .then(res => {
                setLoading(false);
                SuccessAlert(res.data.successMessage);
                refreshParentData();
            })
            .catch(err => {
                setLoading(false);
                console.error('Error adding contract payment:', err);
                ErrorAlert(err?.response?.data?.errorMessage || 'Failed to add contract payment');
            });
    };

    return (
        <div>
            <Button type="primary" onClick={showModal}>
                Add Payment Record
            </Button>
            <Modal title="Add Manual Payment" footer={false} open={isModalOpen} onCancel={handleCancel}>
                <form>
                    <div>
                        <Input
                            type='number'
                            placeholder="Amount"
                            value={formData.amount}
                            onChange={(e) => handleChange("amount", e.target.value)}
                        />
                    </div>
                    <div className='my-3'>
                        <DatePicker
                            className='w-100'
                            value={formData.date ? moment(formData.date) : null}
                            onChange={(date, dateString) => handleChange("date", dateString)}
                        />
                    </div>
                    <div>
                        <Input
                            placeholder="Description"
                            value={formData.description}
                            onChange={(e) => handleChange("description", e.target.value)}
                        />
                    </div>
                    <div className='mt-4'>
                        <Button type="primary" className='w-100' htmlType='button' onClick={submitHandler}>Add</Button>
                    </div>
                </form>
            </Modal>
        </div>
    )
}

export default ManualPayments
