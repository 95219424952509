import { Button, Input } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ErrorAlert, SuccessAlert } from "../../../components/Messages/Messages";
import '../Auth.css';

export const UpdatePassword = (props) => {
  const { token } = useParams();
  const router = useHistory();
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const [loading, setLoading] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (password === confirm) {
      setLoading(true);
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/users/update-password`, { password: password, confirm: confirm, token }).then((res) => {
        setLoading(false);
        if (res.status === 200) {
          SuccessAlert(res.data.successMessage)
          router.push('/login');
        }
        else {
          ErrorAlert(res.data.errorMessage)
        }
      }).catch(error => {
        console.log(error.response);
        setLoading(false);
        ErrorAlert(`Error occured! ${error.message}`)
      });
    } else {
      ErrorAlert("Passwords don't match")
    }
  };


  return (
    <div className='auth'>
      <div className='auth-inner-bubble-container'>
        <h2 className="text-center fs-3">Update Password</h2>
        <form onSubmit={submitHandler}>
          <div className='item'>
            <label>Password</label>
            <div className='item'>
              <Input.Password type="passsord" required className="form-control" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
            </div>
            <div className='item'>
              <label>Retype Password</label>
              <div className='item'>
                <Input.Password type="passsord" required className="form-control" placeholder="Retype Password" onChange={(e) => setConfirm(e.target.value)} />
              </div>
            </div>
          </div>
          <Button loading={loading} disabled={loading} type='primary' htmlType="submit" className='w-100 mt-5'>Submit</Button>
        </form>
      </div>
    </div>
  );
};
