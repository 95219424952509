import React, { useState, useEffect } from 'react';
import { Button, Input, Form, DatePicker, Select } from 'antd';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { ErrorAlert, SuccessAlert } from '../../../components/Messages/Messages';
import DragUpload from '../../../components/DragUpload/DragUpload';
import { isAuthenticated } from '../../../components/Auth/auth';
import "./Contracts.css";
import ReactQuill from 'react-quill';

const { Option } = Select;

const CreateContracts = () => {
    const router = useHistory();
    const [loading, setLoading] = useState(false);
    const [clients, setClients] = useState([]);
    const [items, setItems] = useState([{ description: '', price: '', duration: '' }]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [formData, setFormData] = useState({
        user: isAuthenticated()?._id,
        client: '',
        title: '',
        files: [],
        startDate: '',
        endDate: '',
        currency: 'USD',
        description: '',
        createdBy: isAuthenticated()?._id
    });

    useEffect(() => {
        // Fetch clients from backend API
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/users/all-clients`, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('token')
            }
        })
            .then(response => {
                setClients(response.data);
            })
            .catch(error => {
                console.error('Error fetching clients:', error);
            });
    }, []);

    useEffect(() => {
        calculateTotalAmount(items);
    }, [items]);

    const handleChange = (key, value) => {
        setFormData({
            ...formData,
            [key]: value
        });
    };

    const handleItemChange = (index, key, value) => {
        const newItems = [...items];
        newItems[index][key] = value;
        setItems(newItems);
    };

    const calculateTotalAmount = (items) => {
        const total = items.reduce((acc, item) => acc + (parseFloat(item.price) || 0), 0);
        setTotalAmount(total);
    };

    const addItem = () => {
        setItems([...items, { description: '', price: '', duration: '' }]);
    };

    const removeItem = (index) => {
        const newItems = items.filter((_, itemIndex) => itemIndex !== index);
        setItems(newItems);
    };

    const submitHandler = (e) => {
        e.preventDefault();
        if (formData?.description && formData?.client) {
            setLoading(true);
            const finalData = { ...formData, items, totalAmount };
            formData.files = formData.files?.filter(f => f !== null)
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/contracts/create-contract`, finalData, {
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem('token')
                }
            })
                .then(res => {
                    if (res.status === 200) {
                        setLoading(false);
                        SuccessAlert(res.data.successMessage);
                        router.push("/");
                    } else {
                        ErrorAlert(res.data.errorMessage);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    console.log(err);
                    ErrorAlert(err?.message);
                });
        } else {
            ErrorAlert("Please fill in required fields")
        }
    };

    return (
        <div className='ManageContracts'>
            <h2>Create Contract</h2>
            <form onSubmit={submitHandler}>
                <Form.Item label="Title" required>
                    <Input required placeholder="Enter Contract Title" onChange={(e) => handleChange("title", e.target.value)} />
                </Form.Item>
                <Form.Item label="Terms & Description" required requiredMark>
                    <ReactQuill
                        className='quill'
                        value={formData.description}
                        onChange={(value) => handleChange("description", value)}
                        placeholder="Enter contract terms and description"
                    />
                </Form.Item>
                <Form.Item label="Client" required requiredMark>
                    <Select allowClear showSearch placeholder="Select Client" onChange={(value) => handleChange("client", value)}>
                        {clients.map(client => (
                            <Option key={client?._id} value={client?._id}>{client.fullName}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="Files">
                    <DragUpload accept="image/*" updateFiles={(val) => handleChange("files", val)} />
                </Form.Item>
                <Form.Item label="Start Date">
                    <DatePicker className='w-100' onChange={(date, dateString) => handleChange("startDate", dateString)} />
                </Form.Item>
                <Form.Item label="End Date">
                    <DatePicker className='w-100' onChange={(date, dateString) => handleChange("endDate", dateString)} />
                </Form.Item>
                <Form.Item label="Currency">
                    <Select value={formData.currency} onChange={(value) => handleChange("currency", value)}>
                        <Option value="USD">USD</Option>
                        <Option value="EUR">EUR</Option>
                    </Select>
                </Form.Item>
                <div className="items-section">
                    <h3 className='text-start'>Items</h3>
                    {items.map((item, index) => (
                        <div key={index} className="item-row">
                            <Input
                                placeholder="Description"
                                value={item.description}
                                onChange={(e) => handleItemChange(index, "description", e.target.value)}
                            />
                            <Input
                                type='number'
                                placeholder="Price"
                                value={item.price}
                                onChange={(e) => handleItemChange(index, "price", e.target.value)}
                            />
                            <Input
                                placeholder="Duration"
                                value={item.duration}
                                onChange={(e) => handleItemChange(index, "duration", e.target.value)}
                            />
                            <Button type="danger" onClick={() => removeItem(index)}>Remove</Button>
                        </div>
                    ))}
                    <Button type="dashed" onClick={addItem}>Add Item</Button>
                </div>

                <Form.Item className='mt-3' label="Total Amount">
                    <Input value={totalAmount} disabled />
                </Form.Item>

                <Button type='primary' htmlType="submit" loading={loading} disabled={loading} className="w-100 mt-4">Submit</Button>
            </form>
        </div>
    );
};

export default CreateContracts;
