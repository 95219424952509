import { Button, Input } from 'antd';
import axios from 'axios';
import React, { useState } from 'react'
import { Link } from "react-router-dom"
import { ErrorAlert, SuccessAlert } from "../../../components/Messages/Messages";
import '../Auth.css';

export const SendResetPasswordLink = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        setEmail(e.target.value);
    }

    const submitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/users/send/forgot-email`, { email }).then(res => {
            setLoading(false);
            if (res.status === 200) {
                SuccessAlert(res.data.successMessage);
            }
            else {
                ErrorAlert(res.data.errorMessage);
            }
        }).catch(error => {
            console.log(error.response);
            setLoading(false);
            ErrorAlert(`Error occured! ${error.response.data}`)
        })

    }
    return (
        <div className='auth'>
            <div className='auth-inner-bubble-container'>
                <div className='text-center'>
                    <h2 className='fs-3'>Reset Password</h2>
                </div>
                <form onSubmit={submitHandler}>
                    <div className='item'>
                        <label>Email</label>
                        <div className='item'>
                            <Input name='email' required type="email" className="form-control" placeholder="Email" onChange={handleChange} aria-label="Username" aria-describedby="basic-addon1" />
                        </div>
                    </div>
                    <Button loading={loading} disabled={loading} type='primary' htmlType="submit" className='w-100 mt-5'>Submit</Button>
                    <Link to="/login" className='btn w-100 mt-4'>Cancel</Link>
                </form>
            </div>
        </div >
    )
}
