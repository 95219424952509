import { Button, Input } from 'antd'
import React, { useState } from 'react'
import axios from 'axios'
import { ErrorAlert, SuccessAlert } from '../../../components/Messages/Messages'
import { useHistory } from 'react-router-dom';
import DragUpload from '../../../components/DragUpload/DragUpload';
import "./Clients.css"
import { isAuthenticated } from '../../../components/Auth/auth';

const CreateClients = () => {
    const router = useHistory();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        password: '',
        picture: {}
    });

    /*********************************************** onChange *******************************************/
    const handleChange = (key, value) => {
        setFormData({
            ...formData,
            [key]: value
        });
    }

    /************************************************ Submit **********************************************/

    const submitHandler = (e) => {
        e.preventDefault();
        setLoading(true);
        formData.role = 1;
        formData.createdBy = isAuthenticated()?._id;
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/users/signup`, formData, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }).then(res => {
            if (res.status === 200) {
                setLoading(false);
                SuccessAlert(res.data.successMessage);
                router.push("/user/clients")
            }
            else {
                ErrorAlert(res.data.errorMessage);
            }
        }).catch(err => {
            setLoading(false);
            console.log(err)
            ErrorAlert(err?.message);
        })
    }


    return (
        <div className='ManageClients'>
            <h2>Create clients</h2>
            <form onSubmit={submitHandler}>
                <div className='my-3'>
                    <label>Picture *</label> < br />
                    <DragUpload accept="image/*" noMultiple updateFiles={(val) => handleChange("picture", val[0])} />
                </div>
                <div className="form-group mt-4">
                    <label>Full Name *</label> < br />
                    <Input required type="text" className="form-control mb-2" placeholder="Enter Your Clients Full Name" onChange={(e) => handleChange("fullName", e.target.value)} />
                </div>
                <div className="form-group mt-4">
                    <label>Email *</label> < br />
                    <Input required type="email" className="form-control mb-2" placeholder="Enter Your Clients Email" onChange={(e) => handleChange("email", e.target.value)} />
                </div>
                <div className="form-group mt-4">
                    <label>Password *</label> < br />
                    <Input.Password required className="form-control mb-2" placeholder="Enter Clients's Password" onChange={(e) => handleChange("password", e.target.value)} />
                </div>
                <Button type='primary' htmlType="submit" loading={loading} disabled={loading} className="mt-4 w-100">Submit</Button>
            </form>
        </div>
    )
}

export default CreateClients
