import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { isAuthenticated } from '../components/Auth/auth';

const UserOnlyRoutes = ({ component: Component, ...rest }) => {
    const [done, setDone] = useState(false);

    useEffect(() => {
        if (!isAuthenticated()) {
            setTimeout(() => {
                setDone(true);
            }, 1000);
            localStorage.setItem("redirectUrl", window.location.pathname);
        } else {
            setDone(true);
            localStorage.removeItem("redirectUrl");
        }

        return () => {

        }
    }, []);

    return (
        done &&
        <>
            <Route
                {...rest}
                render={(props) =>
                    isAuthenticated() && isAuthenticated()?.role === 0 ? (
                        <Component {...props} />
                    ) : (
                        <Redirect to='/login' />
                    )
                }
            />
        </>
    )
};

export default UserOnlyRoutes;
