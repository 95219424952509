import { Button, Pagination, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { DeleteOutlined, EditOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons'
import axios from 'axios'
import moment from 'moment'
import { Link, useHistory } from 'react-router-dom';
import { ErrorAlert, SuccessAlert } from '../../../components/Messages/Messages'
import "./Clients.css";

const Clients = () => {
    const router = useHistory();
    const [loading, setLoading] = useState(false);
    const [clients, setClients] = useState([]);
    const [current, setCurrent] = useState(1);
    const [totalCount, setTotalCount] = useState();

    const getAllData = async () => {
        setLoading(true);
        await axios.get(`${process.env.REACT_APP_BACKEND_URL}/users/clients/${current - 1}`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            }
        }).then(res => {
            setLoading(false);
            if (res.status === 200) {
                setClients(res.data?.clients);
                setTotalCount(res.data.count)
            }
            else {
                ErrorAlert(res.data.errorMessage);
            }
        }).catch(err => {
            setLoading(false);
            console.log(err)
        });
    }

    useEffect(() => {
        getAllData()

        return () => {

        }
    }, [current]);



    const deleteHandler = async (id) => {
        await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/clients/delete/${id}`, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem("token")
            }
        }).then(res => {
            if (res.statusText === "OK") {
                SuccessAlert(res.data.successMessage)
                getAllData();
            } else {
                ErrorAlert(res.data.errorMessage)
            }
        }).catch(err => {
            setLoading(false);
            console.log(err)
            ErrorAlert(err?.message);
        })
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: '_id',
            key: '_id',
            sorter: (a, b) => a?._id - b?._id,
        },
        {
            title: "Picture",
            dataIndex: 'picture',
            key: 'picture',
            render: (_, { picture }) => (
                <div >
                    <img src={picture?.url} width={32} height={32} style={{ width: "32px", height: "32px" }} />
                </div>
            ),
        },
        {
            title: "Full Name",
            dataIndex: 'fullName',
            key: 'fullName',
            sorter: (a, b) => a?.fullName?.localeCompare(b?.fullName),
        },
        {
            title: "Email",
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => a?.email?.name?.localeCompare(b?.email)
        },
        {
            title: "Added Date",
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: (a, b) => a.createdAt.length - b.createdAt.length,
            render: (_, { createdAt }) => (
                <p>
                    {moment(createdAt).format("DD/MM/YYYY")}
                </p>
            ),
        },
        {
            title: "Actions",
            render: (_, client) => (
                <>
                    <div className='d-flex align-items-center gap-2'>
                        <EditOutlined onClick={() => router.push(`/user/update-client/${client._id}`)} />
                        {/* <DeleteOutlined onClick={() => deleteHandler(client?._id)} /> */}
                    </div>
                </>
            ),
        },
    ];


    return (
        <div className='clients'>
            <div className='px-4'>
                <div className='py-5 d-flex align-items-center justify-content-between'>
                    <h3 className='mb-0'>Clients</h3>
                    <Button type='primary' onClick={() => router.push("/user/create-client")} className='d-flex align-items-center justify-content-center gap-2 px-6'>
                        <PlusOutlined />
                        <span>Add a client</span>
                    </Button>
                </div>
                <div className='bg-white'>
                    <Table loading={loading} showSorterTooltip columns={columns} pagination={false} dataSource={clients} />
                </div>
                <div className='d-flex justify-content-center my-5'>
                    <Pagination showSizeChanger={false} current={current} onChange={(page) => setCurrent(page)} pageSize={20} total={totalCount} />
                </div>
            </div>
        </div>
    )
}

export default Clients
