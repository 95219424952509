import { Button, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import DragUpload from '../../../components/DragUpload/DragUpload';
import { useHistory } from "react-router-dom";
import { ErrorAlert, SuccessAlert } from '../../../components/Messages/Messages'
import "./Profile.css"
import { isAuthenticated } from '../../../components/Auth/auth';

const Profile = () => {
    const router = useHistory();
    let userId = isAuthenticated()?._id;
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        picture: {}
    });

    /*********************************************** onChange *******************************************/
    const handleChange = (key, value) => {
        setFormData({
            ...formData,
            [key]: value
        });
    }

    /************************************************ Submit **********************************************/

    const submitHandler = (e) => {
        e.preventDefault();
        setLoading(true);
        axios.put(`${process.env.REACT_APP_BACKEND_URL}/users/update/${userId}`, formData, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }).then(res => {
            setLoading(false);
            if (res.status === 200) {
                SuccessAlert(res.data.successMessage);
            }
            else {
                ErrorAlert(res.data.errorMessage);
            }
        }).catch(err => {
            setLoading(false);
            console.log(err)
            ErrorAlert(err?.message);
        })
    }

    const getClientById = async (usId) => {
        await axios.get(`${process.env.REACT_APP_BACKEND_URL}/users/user/${usId}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }).then(res => {
            if (res.statusText === "OK") {
                setFormData(res.data);
            } else {
                ErrorAlert(res.data.errorMessage);
            }
        }).catch(err => {
            setLoading(false);
            console.log(err)
            ErrorAlert(err?.message);
        })
    }

    useEffect(() => {
        getClientById(userId);

        return () => {

        }
    }, [userId]);

    return (
        <div className='ManageProfile'>
            <div>
                <h1 className='mb-4'>Profile</h1>
                <form onSubmit={submitHandler}>
                    <div className='my-3'>
                        <label>Picture *</label> < br />
                        <DragUpload value={[formData?.picture]} accept="image/*" noMultiple updateFiles={(val) => handleChange("picture", val[0])} />
                    </div>
                    <div className="form-group mt-4">
                        <label>Full Name *</label> < br />
                        <Input value={formData.fullName} required type="text" className="form-control mb-2" placeholder="Enter Your Clients Fu llName" onChange={(e) => handleChange("fullName", e.target.value)} />
                    </div>
                    <div className="form-group mt-4">
                        <label>Email *</label> < br />
                        <Input value={formData.email} required type="email" className="form-control mb-2" placeholder="Enter Your Clients Email" onChange={(e) => handleChange("email", e.target.value)} />
                    </div>
                    <Button type='primary' htmlType="submit" loading={loading} disabled={loading} className="mt-4 w-100">Submit</Button>
                    <Button type='ghost' htmlType='button' onClick={() => router.push("/user/change-password")} className="mt-4 w-100">ChangePassword</Button>
                </form>
            </div>
        </div>
    )
}

export default Profile;
