import { Button, Pagination, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { EditOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons'
import axios from 'axios'
import moment from 'moment'
import { useHistory } from 'react-router-dom';
import { ErrorAlert, SuccessAlert } from '../../../components/Messages/Messages'
import "./Contracts.css";
import { isAuthenticated } from '../../../components/Auth/auth'

const Contracts = () => {
    const router = useHistory();
    const [loading, setLoading] = useState(false);
    const [contracts, setContracts] = useState([]);
    const [current, setCurrent] = useState(1);
    const [totalCount, setTotalCount] = useState();
    let role = isAuthenticated()?.role === 0 ? "user" : "client";

    const getAllData = async () => {
        setLoading(true);
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/contracts/${role}/contracts/${current - 1}`, { ss: "" }, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            }
        }).then(res => {
            setLoading(false);
            if (res.status === 200) {
                setContracts(res.data?.contracts);
                setTotalCount(res.data.count)
            }
            else {
                ErrorAlert(res.data.errorMessage);
            }
        }).catch(err => {
            setLoading(false);
            console.log(err)
        });
    }

    useEffect(() => {
        getAllData()

        return () => {

        }
    }, [current]);



    const deleteHandler = async (id) => {
        await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/contracts/delete/${id}`, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem("token")
            }
        }).then(res => {
            if (res.statusText === "OK") {
                SuccessAlert(res.data.successMessage)
                getAllData();
            } else {
                ErrorAlert(res.data.errorMessage)
            }
        }).catch(err => {
            setLoading(false);
            console.log(err)
            ErrorAlert(err?.message);
        })
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: '_id',
            key: '_id',
            sorter: (a, b) => a?._id - b?._id,
        },
        {
            title: "Title",
            dataIndex: 'title',
            key: 'title',
            sorter: (a, b) => a?.title?.localeCompare(b?.title),
        },
        {
            title: "Client",
            dataIndex: 'client',
            key: 'client',
            render: (_, contract) => (
                <div>
                    {contract?.client?.fullName}
                </div>
            ),
        },
        {
            title: "Start Date",
            dataIndex: 'startDate',
            key: 'startDate',
            sorter: (a, b) => moment(a.startDate).unix() - moment(b.startDate).unix(),
            render: startDate => (
                <div>{moment(startDate).format("DD/MM/YYYY")}</div>
            ),
        },
        {
            title: "End Date",
            dataIndex: 'endDate',
            key: 'endDate',
            sorter: (a, b) => moment(a.endDate).unix() - moment(b.endDate).unix(),
            render: endDate => (
                <div>{moment(endDate).format("DD/MM/YYYY")}</div>
            ),
        },
        {
            title: "Total Amount",
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            render: (_, contract) => (
                <div>
                    {contract?.currency}{contract?.totalAmount}
                </div>
            ),
        },
        {
            title: "Signed Status",
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: "Payment Status",
            dataIndex: 'paymentStatus',
            key: 'paymentStatus',
        },
        {
            title: "Added Date",
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
            render: createdAt => (
                <div>{moment(createdAt).format("DD/MM/YYYY")}</div>
            ),
        },
        {
            title: "Actions",
            render: (_, contract) => (
                <>
                    <div className='d-flex align-items-center gap-2'>
                        <EyeOutlined onClick={() => router.push(`/contract/${contract._id}`)} />
                        {
                            isAuthenticated()?.role === 0 &&
                            <EditOutlined onClick={() => router.push(`/user/update-contract/${contract._id}`)} />
                        }
                        {/* <DeleteOutlined onClick={() => deleteHandler(contract?._id)} /> */}
                    </div>
                </>
            ),
        },
    ];


    return (
        <div className='Contracts'>
            <div className='px-4'>
                <div className='py-5 d-flex align-items-center justify-content-between'>
                    <h3 className='mb-0'>Contracts</h3>
                    {
                        isAuthenticated()?.role === 0 &&
                        <Button type='primary' onClick={() => router.push("/user/create-contract")} className='d-flex align-items-center justify-content-center gap-2 px-4'>
                            <PlusOutlined />
                            <span>Add a contract</span>
                        </Button>
                    }
                </div>
                <div className='bg-white'>
                    <Table loading={loading} showSorterTooltip columns={columns} pagination={false} dataSource={contracts} />
                </div>
                <div className='d-flex justify-content-center my-5'>
                    <Pagination showSizeChanger={false} current={current} onChange={(page) => setCurrent(page)} pageSize={20} total={totalCount} />
                </div>
            </div>
        </div>
    )
}

export default Contracts
