import React from 'react'
import { Link } from "react-router-dom";
import { isAuthenticated, logout } from '../Auth/auth';
import { LogoutOutlined } from '@ant-design/icons';
import "./Navbar.css";

const Navbar = () => {
    return (
        <nav className='Navbar'>
            <h3>
                <Link to="/">DJ AK21</Link>
            </h3>
            <div>
                {
                    isAuthenticated() ?
                        <>
                            {
                                isAuthenticated()?.role === 0 &&
                                <Link to="/user/clients" className='bg-transparent'>
                                    Clients
                                </Link>
                            }
                            <Link to="/" className='bg-transparent'>
                                Contracts
                            </Link>
                            <Link to="/user/profile" className='bg-transparent'>
                                Profile
                            </Link>
                            <a href="/login" className='bg-transparent' onClick={logout}>
                                <LogoutOutlined />
                            </a>
                        </>
                        :
                        <Link to="/login">
                            Get Started
                        </Link>
                }
            </div>
        </nav>
    )
}

export default Navbar
