import React, { useState } from 'react';
import axios from 'axios';
import './Auth.css';
import { ErrorAlert, SuccessAlert } from '../../components/Messages/Messages';
import { Link } from 'react-router-dom';
import { Button, Input } from 'antd';

export const Signup = (props) => {
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState({
        fullName: '',
        email: '',
        password: '',
        confirm: '',
    });

    const { password, confirm } = userData;

    const handleChange = (e) => {
        setUserData({
            ...userData,
            [e.target.name]: e.target.value
        });
    }

    const submitHandler = async (e) => {
        e.preventDefault();
        if (password !== confirm) {
            ErrorAlert("Passwords don't match");
        } else {
            setLoading(true);
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/users/signup`, userData).then(res => {
                setLoading(false);
                if (res.status === 200) {
                    SuccessAlert(res.data.successMessage);
                    setTimeout(() => {
                        props.history.push('/login')
                    }, 2000);
                }
                else {
                    ErrorAlert(res.data.errorMessage);
                }
            }).catch(err => {
                setLoading(false);
                ErrorAlert(err?.message);
            })
        }
    };

    return (
        <div className='auth'>
            <div className="auth-inner-bubble-container">
                <h2>Create account</h2>
                <form onSubmit={submitHandler}>
                    <div className='item'>
                        <label>Full Name</label>
                        <Input required name='fullName' type="text" className="form-control" placeholder="Full Name" onChange={handleChange} />
                    </div>
                    <div className='item'>
                        <label>Email</label>
                        <Input required name='email' type="text" className="form-control" placeholder="Email" onChange={handleChange} />
                    </div>
                    <div className='item'>
                        <label>Password</label>
                        <Input.Password required name='password' type="password" className="form-control" placeholder="Password" onChange={handleChange} />
                    </div>
                    <div className='item'>
                        <label>Retype Password</label>
                        <Input.Password required name='confirm' type="password" className="form-control" placeholder="Retype Password" onChange={handleChange} />
                    </div>
                    <Button type='primary' className='mt-4 w-100' loading={loading} disabled={loading} htmlType="submit">Signup</Button>
                </form>
                <div className='end-text'>
                    <div>Already have an account?</div>
                    <Link to="/login">
                        <b className='fw-bold'>Login</b>
                    </Link>
                </div>
            </div>
        </div>
    );
};
