import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './PaymentPage.css'
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Col, Row } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { ErrorAlert, SuccessAlert } from '../../components/Messages/Messages';
import Loading from '../../components/Loading/Loading';
import { StripeForm } from '../../components/Payment/StripeForm';
import moment from 'moment';


const PaymentPage = () => {
    const { id } = useParams();
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
    const router = useHistory();
    const [loading, setLoading] = useState(false);
    const [stripeLoading, setStripeLoading] = useState(false);
    const [clientSecret, setClientSecret] = useState("");
    const totalPrice = parseInt(localStorage.getItem("totalPrice"));
    const currency = localStorage.getItem("currency");

    const transactionSuccess = async (data) => {
        setLoading(true);
        await axios.put(`${process.env.REACT_APP_BACKEND_URL}/contracts/make-payment`,
            {
                contractId: id,
                paymentData: data,
                totalPrice,
                date: moment().format("YYYY-MM-DD")
            }
            , {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            }
        )
            .then(res => {
                setLoading(false)
                if (res.status === 200) {
                    SuccessAlert(res.data.successMessage);
                    localStorage.removeItem("totalPrice");
                    router.push(`/contract/${id}`);
                } else {
                    ErrorAlert(res.data.errorMessage)
                }
            }).catch(err => {
                console.log(err);
                ErrorAlert(err?.message);
            })

    }

    const appearance = {
        theme: 'stripe',
    };

    const options = {
        clientSecret,
        appearance,
    }


    const createPaymentIntent = async () => {
        setStripeLoading(true);
        await axios.put(`${process.env.REACT_APP_BACKEND_URL}/contracts/create-payment-intent`, { totalPrice, currency }, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }).then((res) => {
            setClientSecret(res.data.clientSecret);
            setStripeLoading(false)
        }).catch(err => {
            setStripeLoading(false)
            console.log(err)
        })
    }

    useEffect(() => {
        if (totalPrice && currency) {
            createPaymentIntent();
        } else {
            router.push("/user/contracts")
        }

        return () => {

        }
    }, [totalPrice]);



    return (
        <div className="PaymentPage">
            <Row>
                <Col xs={24} className="inner">
                    <h1 className="title">Payment</h1>
                    <div className="formPaymenContainer">
                        <h5 className='text-center mb-4'>Total payment to be made {totalPrice}{currency}</h5>
                        {
                            <div className="paymentInformation">
                                <div className="paymentContainer">
                                    {
                                        stripeLoading ?
                                            <Loading />
                                            :
                                            clientSecret &&
                                            (
                                                <div>
                                                    <Elements options={options} stripe={stripePromise}>
                                                        <StripeForm totalPrice={parseInt(totalPrice)} placeOrder={transactionSuccess} />
                                                    </Elements>
                                                </div>
                                            )
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default PaymentPage;