import { BrowserRouter, Route, Switch } from "react-router-dom"
import { Login } from "./pages/Auth/Login";
import { Signup } from "./pages/Auth/Signup";
import Navbar from "./components/Navbar/Navbar";
import { SendResetPasswordLink } from "./pages/Auth/Forgot-Password/SendResetPasswordLink";
import { UpdatePassword } from "./pages/Auth/Forgot-Password/UpdatePassword";
import Clients from "./pages/User/Clients/Clients";
import CreateClients from "./pages/User/Clients/CreateClients";
import UpdateClient from "./pages/User/Clients/UpdateClients";
import Profile from "./pages/User/Profile/Profile";
import ChangePassword from "./pages/User/ChangePassword/ChangePassword";
import Contracts from "./pages/User/Contracts/Contracts";
import CreateContracts from "./pages/User/Contracts/CreateContracts";
import UpdateContract from "./pages/User/Contracts/UpdateContracts";
import ContractDetails from "./pages/User/ContractDetails/ContractDetails";
import 'react-quill/dist/quill.snow.css'; // Import styles for React Quill
import ProtectedRoute from "./routes/ProtectedRoute";
import UserOnlyRoutes from "./routes/UserOnlyRoutes";
import PaymentPage from "./pages/Payment/PaymentPage";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <div className="no-print">
          <Navbar />
        </div>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/forgot-password" component={SendResetPasswordLink} />
          <Route exact path="/reset-password/:token" component={UpdatePassword} />
          <ProtectedRoute exact path="/user/profile" component={Profile} />
          <ProtectedRoute exact path="/user/change-password" component={ChangePassword} />
          <UserOnlyRoutes exact path="/user/clients" component={Clients} />
          <UserOnlyRoutes exact path="/user/create-client" component={CreateClients} />
          <UserOnlyRoutes exact path="/user/update-client/:id" component={UpdateClient} />
          <ProtectedRoute exact path="/" component={Contracts} />
          <UserOnlyRoutes exact path="/user/create-contract" component={CreateContracts} />
          <UserOnlyRoutes exact path="/user/update-contract/:id" component={UpdateContract} />
          <ProtectedRoute exact path="/contract/:id" component={ContractDetails} />
          <ProtectedRoute exact path="/payment/:id" component={PaymentPage} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
