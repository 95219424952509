import { Button, Form, Input } from 'antd';
import axios from 'axios';
import "./ChangePassword.css";
import React, { useState } from 'react';
import { ErrorAlert, SuccessAlert } from '../../../components/Messages/Messages';
import { useHistory } from 'react-router-dom';

const ChangePassword = () => {
    const router = useHistory();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onFinish = async (values) => {
        setLoading(true);
        await axios.put(`${process.env.REACT_APP_BACKEND_URL}/users/change-password`, values, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            }
        }).then(res => {
            setLoading(false);
            if (res.status === 200) {
                SuccessAlert(res.data.successMessage);
                router.push("/user/profile");
            } else {
                ErrorAlert(res.data.errorMessage);
            }
        }).catch(err => {
            setLoading(false);
            console.log(err)
            ErrorAlert(err?.message);
        })
    };


    return (
        <div className='ChangePassword'>
            <div>
                <h1 className='mb-4'>Change Password</h1>
                <Form
                    layout="vertical"
                    form={form}
                    name="nest-messages"
                    className="form"
                    requiredMark={false}
                    onFinish={onFinish}
                    style={{
                        maxWidth: 600,
                    }}
                >
                    <Form.Item
                        name="oldPassword"
                        label="Old Password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your old password!',
                            },
                        ]}
                        hasFeedback
                    >
                        <Input.Password placeholder='Enter Old password' />
                    </Form.Item>
                    <Form.Item
                        name="newPassword"
                        label="New Password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your new password!',
                            },
                        ]}
                        hasFeedback
                    >
                        <Input.Password placeholder='Enter New password' />
                    </Form.Item>
                    <Form.Item
                        name="confirm"
                        label="Confirm Password"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('newPassword') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The new password that you entered do not match!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password placeholder='Confirm password' />
                    </Form.Item>
                    <Form.Item>
                        <Button type='primary' className='w-100 mt-4' htmlType='submit' loading={loading} disabled={loading}>Submit</Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default ChangePassword
