import { Button, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import DragUpload from '../../../components/DragUpload/DragUpload';
import { useHistory, useLocation } from "react-router-dom";
import { ErrorAlert, SuccessAlert } from '../../../components/Messages/Messages'

const UpdateClient = () => {
    const location = useLocation();
    const router = useHistory();
    let clientId = location.pathname.split("client/")[1];
    const [loading, setLoading] = useState(false);
    const [client, setClient] = useState({});
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        picture: {}
    });

    /*********************************************** onChange *******************************************/
    const handleChange = (key, value) => {
        setFormData({
            ...formData,
            [key]: value
        });
    }

    /************************************************ Submit **********************************************/

    const submitHandler = (e) => {
        e.preventDefault();
        setLoading(true);
        axios.put(`${process.env.REACT_APP_BACKEND_URL}/users/update/${clientId}`, formData, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }).then(res => {
            setLoading(false);
            if (res.status === 200) {
                SuccessAlert(res.data.successMessage);
                router.push("/user/clients");
            }
            else {
                ErrorAlert(res.data.errorMessage);
            }
        }).catch(err => {
            setLoading(false);
            console.log(err)
            ErrorAlert(err?.message);
        })
    }

    const getClientById = async (usId) => {
        await axios.get(`${process.env.REACT_APP_BACKEND_URL}/users/user/${usId}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }).then(res => {
            if (res.statusText === "OK") {
                setFormData(res.data);
                setClient(res.data);
            } else {
                ErrorAlert(res.data.errorMessage);
            }
        }).catch(err => {
            setLoading(false);
            console.log(err)
            ErrorAlert(err?.message);
        })
    }

    useEffect(() => {
        getClientById(clientId);

        return () => {

        }
    }, [clientId]);

    return (
        <div className='ManageClients'>
            <h2>Update client</h2>
            <form onSubmit={submitHandler}>
                <div className='my-3'>
                    <label>Picture *</label> < br />
                    <DragUpload value={[formData?.picture]} accept="image/*" noMultiple updateFiles={(val) => handleChange("picture", val[0])} />
                </div>
                <div className="form-group mt-4">
                    <label>Full Name *</label> < br />
                    <Input value={formData.fullName} required type="text" className="form-control mb-2" placeholder="Enter Your Clients Fu llName" onChange={(e) => handleChange("fullName", e.target.value)} />
                </div>
                <div className="form-group mt-4">
                    <label>Email *</label> < br />
                    <Input value={formData.email} required type="email" className="form-control mb-2" placeholder="Enter Your Clients Email" onChange={(e) => handleChange("email", e.target.value)} />
                </div>
                <Button type='primary' htmlType="submit" loading={loading} disabled={loading} className="mt-4 w-100">Submit</Button>
            </form>
        </div>
    )
}

export default UpdateClient;
